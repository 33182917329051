import {useContext, useMemo} from "react";
import {LinkToContext} from "~/contexts/LinkTo.context";
import {LinkTo} from "~/utils/LinkTo";

/**
 * Hook that exposes instance of @see LinkTo class.
 * It is meant to be used for methods that require environment-dependant urls that are loaded and injected by Remix.
 * @see LinkTo
 */
export function useLinkTo(): {linkTo: LinkTo} {
    const {apiUrl} = useContext(LinkToContext);
    const linkToInstance = useMemo(() => new LinkTo(apiUrl), [apiUrl]);

    return {linkTo: linkToInstance};
}
